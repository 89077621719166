import React from "react"
import "./style.css"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux";


const Footer = () => {
  const storeData = useSelector((state) => state.storeConfig.data);

  return (
    <footer>
        <Container>
          <Row className="footer-row">
            <Col md={3} sm={5} className='box'>
              <div className="logo">
                  <ion-icon name="bag"></ion-icon>
                  <h1>{storeData.name}</h1>
              </div>
              <p>{storeData.tagline}</p>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Locations</h2>
              <ul>
                <li></li>
                {storeData.locations.map((location, index) => (
                  <li key={index}>{location}</li>
                ))}
              </ul>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Contact Us</h2>
              <ul>
                <li> </li>
                {storeData.contact_email && <li>Email: {storeData.contact_email}</li>} 
                {storeData.telephone_number && <li>Telephone No: {storeData.telephone_number}</li>} 
                {storeData.mobile_number && <li>Mobile No: {storeData.mobile_number}</li>} 
              </ul>
            </Col>
          </Row>
        </Container>
    </footer>
  )
}

export default Footer
