import { configureStore } from "@reduxjs/toolkit";
import cartSlice, { cartMiddleware } from "./features/cart/cartSlice";
import sliderReducer from "./features/init/sliderSlice";
import storeConfigReducer from "./features/init/storeConfigSlice";
import { initializeApp } from './features/init/appInitialization';

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    slider: sliderReducer,
    storeConfig: storeConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartMiddleware),
});

store.dispatch(initializeApp());
