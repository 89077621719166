import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch store configuration data
export const fetchStoreConfig = createAsyncThunk("storeConfig/fetchStoreConfig", async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/store-config/`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
});

const storeConfigSlice = createSlice({
    name: "storeConfig",
    initialState: {
        data: {
            name:"Umbreen Boutique",
            tagline:"Elegance Redefined, Style Perfected – Discover Your Unique Look with Umbreen Boutique.",
            contact_email:"umbreen@gmail.com",
            telephone_number:"+923327586743",
            mobile_number:"",
            locations: []
        },
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStoreConfig.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchStoreConfig.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchStoreConfig.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default storeConfigSlice.reducer;
