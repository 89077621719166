import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch slider data
export const fetchSlider = createAsyncThunk("slider/fetchSlider", async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/slider/`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json(); // Parse the response as JSON
    return data; // Return the actual data
});

const sliderSlice = createSlice({
    name: "slider",
    initialState: {
        data: [],
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlider.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchSlider.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchSlider.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default sliderSlice.reducer;
